export default {
  props: {
    latitude: {
      type: String,
      default: '',
    },
    longitude: {
      type: String,
      default: '',
    },
    googleMapLink: {
      type: String,
      default: '',
    },
  },
  computed: {
    url() {
      return this.googleMapLink
        ? this.googleMapLink
        : this.latitude && this.longitude
          ? 'https://www.google.com/maps/search/?api=1&query=' + this.latitude + ',' + this.longitude
          : ''
    },
  },
}
