import BranchBox from '@theme/components/content/BranchBox'
import Loader from '@theme/components/utils/Loader'
import LoadingMixin from '~/mixins/LoadingMixin'
import Modal from '@theme/components/utils/Modal'

export default {
  components: {
    BranchBox,
    Loader,
    Modal,
  },
  mixins: [LoadingMixin],
  props: {
    branch: Object,
    shipper: Object,
  },
  data() {
    return {
      cmsBranch: null,
    }
  },
  async mounted() {
    if (this.hasCmsBranch) {
      this.startLoading()
      this.cmsBranch = await this.$store.dispatch('branch/LOAD_BRANCH_BY_ID', {
        id: this.branch.contentId,
      })
      this.stopLoading()
    }
  },
  computed: {
    hasCmsBranch() {
      return !!this.branch.contentId
    },
  },
  methods: {
    open() {
      this.$refs.modal.show()
    },
  },
}
