import AppImage from '@theme/components/atom/AppImage'
import AppTitle from '@theme/components/atom/AppTitle'
import GoogleMapLink from '@theme/components/atom/GoogleMapLink'
import OpeningHours from '@theme/components/shop/OpeningHours'
import Wysiwyg from '@theme/components/utils/Wysiwyg'
import get from 'lodash/get'

export default {
  components: {
    AppImage,
    AppTitle,
    GoogleMapLink,
    OpeningHours,
    Wysiwyg,
  },
  props: {
    branch: Object,
  },
  computed: {
    phone() {
      return this.branch.storeBranch.phone && this.branch.storeBranch.phone.replace(/ /g, '')
    },
  },
  methods: {
    getExtraOpeningHours() {
      return get(this.branch, 'storeBranch.businessHours')
    },
  },
}
