import DetectBrowserMixin from '~/mixins/DetectBrowserMixin'

export default {
  mixins: [DetectBrowserMixin],
  props: {
    data: String,
  },
  data() {
    return {
      dayOrder: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
      dayOrderGrouped: null,
      lastGroupedDayIndex: 0,
      openingHours: {},
      status: null,
      today: null,
      weekendDays: ['saturday', 'sunday'],
    }
  },
  computed: {
    openWeekendDays() {
      const oThis = this
      return this.weekendDays.filter(day => {
        return oThis.openingHours[day].length > 0 && !oThis.dayOrderGrouped.includes(day)
      })
    },
  },
  created() {
    this.dayOrder.forEach(day => (this.openingHours[day] = []))
    if (this.data) {
      const days = this.data.split('\n')
      days.forEach(dayLine => {
        if (dayLine) {
          const tmpSplit = dayLine.split('=')
          const day = tmpSplit[0]
          const times = tmpSplit[1]
          if (times) {
            this.openingHours[day] = times.split(',').map(timePair => {
              const timeSplit = timePair.split('-')
              return {
                opens: timeSplit[0].trim(),
                closes: timeSplit[1].trim(),
              }
            })
          }
        }
      })

      let lastGroupedDayIndex = 0
      while (
        days[lastGroupedDayIndex] &&
        days[0] &&
        days[0].split('=')[1] === days[lastGroupedDayIndex].split('=')[1]
      ) {
        lastGroupedDayIndex++
      }
      this.lastGroupedDayIndex = lastGroupedDayIndex > 4 ? lastGroupedDayIndex - 1 : 0
      this.dayOrderGrouped = this.dayOrder.slice(this.lastGroupedDayIndex, lastGroupedDayIndex > 5 ? -1 : -2)
    }
    this.today = this.$moment().format('dd')
  },
  async mounted() {
    this.onload()
  },
  methods: {
    async onload() {
      if (!this.isIE11) {
        const OpeningTimes = (await import('moment-opening-times')).default
        const now = this.$moment()
        const openingTimesMoment = new OpeningTimes(this.openingHours, 'Europe/Prague')
        this.status = openingTimesMoment.getStatus(now)
      }
    },
    dayInWeek(index) {
      return this.$moment()
        .weekday(index)
        .format('dd')
    },
    formatTimes(times) {
      return times.map(time => `${time.opens.trim()}&thinsp;–&thinsp;${time.closes}`).join(',&ensp;')
    },
  },
}
