import OpeningHours from 'themes/base/components/shop/OpeningHours'

export default {
  extends: OpeningHours,
  methods: {
    async onload() {
      if (!this.isIE11) {
        const OpeningTimes = (await import('moment-opening-times')).default
        let openingHoursMap = {
          pondělí: this.openingHours.monday,
          úterý: this.openingHours.tuesday,
          středa: this.openingHours.wednesday,
          čtvrtek: this.openingHours.thursday,
          pátek: this.openingHours.friday,
          sobota: this.openingHours.saturday,
          neděle: this.openingHours.sunday,
        }
        const now = this.$moment()
        const openingTimesMoment = new OpeningTimes(openingHoursMap, 'Europe/Prague')
        this.status = openingTimesMoment.getStatus(now)
      }
    },
  },
}
